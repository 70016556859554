<template>
  <div class="flex flex-col lg:flex-row gap-x-12">
    <div class="w-full lg:w-2/3">
      <div class="bg-white p-6 pt-8 sm:p-8 sm:pt-12 md:p-12 md:pt-16">
        <h1
          class="flex flex-col text-black font-headings text-2xl sm:text-3xl md:text-4xl mb-8 sm:mb-12"
        >
          <span v-text="$t('title.why_unique')" />
          <span
            class="bg-red-500 h-px inline-block w-16 mt-3"
            style="height: 2px"
          />
        </h1>
        <div class="text-gray-700">
          <div v-for="(item, index) in services" :key="item.id">
            <div class="flex flex-col sm:flex-row mb-6">
              <div class="w-full sm:w-1/3 mb-6 sm:mb-0">
                <router-link
                  :to="'/' + $t('router.reports.path') + '/' + item.path"
                  class="relative sm:mr-6 h-0 pt-36 rounded overflow-hidden block"
                >
                  <picture>
                    <source
                      :srcset="
                        `/img/services/service${item.id}.jpg.webp 1x, /img/services/service${item.id}@2x.jpg.webp 2x`
                      "
                      type="image/webp"
                    />
                    <source
                      :srcset="
                        `/img/services/service${item.id}.jpg 1x, /img/services/service${item.id}@2x.jpg 2x`
                      "
                      type="image/jpeg"
                    />
                    <img
                      :src="`/img/services/service${item.id}.jpg`"
                      :alt="item.title"
                      loading="lazy"
                      class="absolute inset-0 w-full h-full object-cover"
                    />
                  </picture>
                </router-link>
              </div>
              <div class="w-full sm:w-2/3">
                <h4
                  class="mb-3 text-xl font-headings text-black"
                  v-text="item.title"
                />
                <p v-text="item.short_description" />
                <p class="text-sm mt-4">
                  <router-link
                    :to="'/' + $t('router.reports.path') + '/' + item.path"
                    class="btn border border-gray-900 border-opacity-20 hover:border-opacity-75"
                    v-text="$t('button.view_more')"
                  />
                </p>
              </div>
            </div>
            <hr
              class="my-6 sm:my-8 hidden sm:block"
              v-if="index < services.length - 1"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-1/3 lg:pl-5">
      <div class="sticky top-0 pt-8">
        <sidebar-cta :ctaText="$t('title.get_started_2')" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarCta from "../../components/SidebarCta.vue";
export default {
  components: { SidebarCta },
  name: "WhyTheAstralTheme",
  computed: {
    services() {
      return this.$store.state.services;
    },
  },
  metaInfo() {
    return { title: this.$t("title.why_unique") };
  },
};
</script>
