<template>
  <div class="relative">
    <h1 class="text-4xl leading-normal font-headings">
      <span v-text="$t('page.homepage.title')" />
    </h1>
    <ul class="mt-12 mb-12 md:mb-12 text-lg">
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_1')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_2')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_3')" />
      </li>
      <li class="flex items-center mb-3">
        <inline-svg
          src="/img/icon-check.svg"
          class="w-5 h-5 mr-3 flex-shrink-0 fill-current"
        />
        <span v-text="$t('page.homepage.feature_4')" />
      </li>
    </ul>
    <h2
      class="font-headings text-xl mb-4"
      v-text="this.ctaText || $t('title.get_started')"
    />
    <router-link
      to="/"
      class="bg-red-500 p-4 text-white w-full flex items-center justify-center text-center font-bold rounded transition-colors active:bg-red-600"
    >
      <span v-text="$t('button.get_started')" />
      <inline-svg
        src="/img/icon-right.svg"
        class="fill-current ml-3 animate-arrow"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarCta",
  props: {
    ctaText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
